<template>
  <div class="notification-container">
    <mng-header page-title="公告详情" msg="">
      <el-button size="small" @click="goBack">返 回</el-button>
    </mng-header>
    <div class="main-body" v-loading="detailLoading">
      <div class="note-body">
        <header>
          <h1>{{noteForm.noticeTitle}}</h1>
          <div class="note-base-info">
            <span class="publish-data">发布时间：{{noteForm.updateTime||'未知'}}</span>
            <span class="editor">发布人：{{noteForm.updateBy||'佚名'}}</span>
          </div>
          <p v-if="noteForm.noticeBrief">{{noteForm.noticeBrief}}</p>
        </header>
        <article>
          <div v-html="noteForm.noticeDetails"></div>
          <!--        <tg-editor :html="noteForm.noticeDetails" :show-menu="false" :editor-config="editorConfig"></tg-editor>-->
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import MngHeader from '@/components/mng-pages/MngHeader'
import { notificationDetail } from '@/service/api/notification/notification'
// import TgEditor from '@/components/editor/TgEditor'

export default {
  name: 'NotificationDetail',
  components: {
    // TgEditor,
    MngHeader
  },
  props: {
    pageData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      detailLoading: false,
      noteForm: {},
      editorConfig: {
        readOnly: true
      }
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    goBack() {
      this.$emit('changePage', { pageId: 'NotificationTable' })
    },
    getDetail() {
      this.detailLoading = true
      notificationDetail({ id: this.pageData.id }).then(res => {
        this.detailLoading = false
        if (res.data.status === 200) {
          this.noteForm = res.data.data
        } else {
          this.$message.error(res.data.msg || '获取公告详情失败！')
        }
      }).catch(e => {
        console.log(e)
        this.detailLoading = false
        this.$message.error('服务异常！')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.notification-container{
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 16px;
  .main-body{
    padding: 0 16px;
    box-sizing: border-box;
    overflow-y: auto;
    .note-body{
      min-height: 100px;
      background-color: #ffffff;
      box-sizing: border-box;
      padding: 30px 40px;
      header{
        box-sizing: border-box;
        h1{
          font-size: 34px;
          color: #000;
          margin: 0 0 20px;
          font-weight: 400;
        }
        .note-base-info{
          .publish-data{
            font-size: 14px;
            color: #999;
          }
          .editor{
            font-size: 14px;
            color: #999;
            margin-left: 20px;
          }
        }
        p {
          padding: 18px;
          outline: 0;
          max-width: 100%;
          font-family: Avenir, -apple-system-font, 微软雅黑, sans-serif;
          color: rgb(89, 89, 89);
          background-color: rgb(239, 239, 239);
          visibility: visible;
          line-height: 1.75em;
          box-sizing: border-box !important;
          overflow-wrap: break-word !important;
        }
      }
      ::v-deep article{
        box-sizing: border-box;
        div{
          word-break: break-all;
          box-sizing: border-box;
          line-height: 1.75em;
          img{
            width: 80%;
          }
        }
      }
    }
  }
}
</style>
